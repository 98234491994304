<template>
  <div class="button-group-wrapper">
    <div v-if="isShowLeft">
      <van-button
        @click="handleUp"
        type="default"
        class="submit-button"
        :disabled="successSubmitLeft"
        size="large"
        :loading="loadingLeft"
        round
      >{{leftText}}</van-button>
    </div>
    <div v-if="isShowRight" :class="['submit-and-order', isShowLeft? 'ml20': '']">
      <van-button
        @click="handleOrder"
        type="primary"
        :loading="loadingRight"
        :disabled="successSubmitRight"
        round
        size="large"
      >{{rightText}}</van-button>
    </div>
  </div>
</template>
<script>
import { Button } from "vant";
export default {
  components: {
    [Button.name]: Button,
  },
  props: {
    successSubmitRight: {
      type: Boolean,
      default: false,
    },
    successSubmitLeft: {
      type: Boolean,
      default: false,
    },
    loadingLeft: {
      type: Boolean,
      default: false,
    },
    loadingRight: {
      type: Boolean,
      default: false,
    },
    leftText: {
      type: String,
      default: "提交",
    },
    rightText: {
      type: String,
      default: "提交并转为订单",
    },
    isShowLeft: {
      type: Boolean,
      default: true,
    },
    isShowRight: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const handleOrder = () => {
      context.emit("handleButtonRight");
    };
    const handleUp = () => {
      context.emit("handleButtonLeft");
    };
    return {
      handleOrder,
      handleUp,
    };
  },
};
</script>
<style lang="less" scoped>
.button-group-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  div {
    flex: 1;
    height: 80px;
    border-radius: 44px;
    font-size: 32px;
    :deep(.van-button--large) {
      height: 100%;
      .van-button__text {
        font-size: 34px;
      }
    }
  }
}
</style>