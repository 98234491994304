<template>
  <div class="success-wrapper">
    <div class="success-img">
      <img src="/imgs/success.png" alt />
    </div>
    <div class="success-content" v-if="currentType == 1">
      <div class="goods-tip">考籍无需支付，已开通成功</div>
      <div class="examation-type">考籍：{{title}}</div>
    </div>
    <div class="success-content" v-else-if="currentType == 2">
      <div class="goods-tip">APP商品开通成功，即时生效</div>
      <div class="examation-type">{{title}}</div>
    </div>
    <div class="success-content" v-else-if="currentType == 3">
      <div class="goods-tip">APP商品已提交财务审核</div>
      <div class="examation-type">{{title}}</div>
    </div>
    <div class="success-content" v-else-if="currentType == 4">
      <div class="goods-tip2">该订单已提交给【{{aduitPerson}}】进行优惠审批 通过后您会收到推送消息（站内/企业微信），并可去支付该订单。</div>
      <div class="examation-type">{{title}}</div>
    </div>
    <div class="footer-wrapper" v-if="currentType == 1">
      <my-footer
        rightText="同时开通APP"
        leftText="暂不开通APP"
        @handleButtonRight="submitRight"
        @handleButtonLeft="submitLeft"
      ></my-footer>
    </div>
    <div class="footer-wrapper" v-else>
      <my-footer rightText="我知道了" :isShowLeft="false" @handleButtonRight="handleClick"></my-footer>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import myFooter from "@/components/buttonGroup";
import { reactive, toRefs } from "vue";
export default {
  components: {
    myFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    // 返回的考籍Id
    const currentId = route.query.id;
    const currentType = route.query.type; // 1开通考籍成功 2开通app成功
    const title = route.query.title;
    const aduitPerson = route.query.aduitPerson; // 审批人
    const examinationData = reactive({
      dataList: {},
    });
    // if (currentType === 1) {
    //  title
    // }
    const submitLeft = () => {
      router.push({
        path: "/client",
      });
    };
    const submitRight = () => {
      const idCard = localStorage.getItem("idCard");
      const businessId = localStorage.getItem("businessId");
      router.push({
        path: "/openApp",
        query: {
          businessId: businessId,
          idCard: idCard,
        },
      });
    };
    const handleClick = () => {
      // 点击我知道了，返回客户画像
      router.push({
        path: "/client",
      });
    };
    return {
      currentId,
      currentType,
      title,
      aduitPerson,
      submitLeft,
      submitRight,
      handleClick,
      ...toRefs(examinationData),
    };
  },
};
</script>
<style lang="less" scoped>
.success-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success-img {
    margin-top: 30vh;
    width: 200px;
    height: 160px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .success-content {
    text-align: center;
    max-width: 550px;
    margin-top: 24px;
    .goods-tip {
      font-size: 40px;
      font-weight: bold;
      color: #333;
    }
    .goods-tip2 {
      font-size: 28px;
      color: #333;
    }
    .examation-type {
      margin-top: 16px;
      font-size: 24px;
      color: #999;
    }
  }
  .footer-wrapper {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    background: #fff;
  }
}
</style>